import BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'

import styles from './TotalValueLocked.module.scss'

const cx = classNames.bind(styles)

interface TotalValueLockedProps {
  value?: BigNumber
}

export const TotalValueLocked = ({ value }: TotalValueLockedProps) => {
  const stylesMainContainer = cx('main__container')

  return (
    <div className={stylesMainContainer}>
      <h6>Total Value Locked:</h6>
      <h5>{value ? value.toFormat(3) : '0.000'} USD</h5>
    </div>
  )
}
