import { useQuery } from '@apollo/react-hooks'
import BigNumber from 'bignumber.js'
import { useEffect, useState } from 'react'
import { ETH_PRICE_QUERY } from 'src/services/queries'
import useAsyncEffect from 'use-async-effect'

interface EthPrice {
  loading: boolean
  value: BigNumber | null
}

export const useGetEthPriceUsd = () => {
  const { loading, error, data: ethPriceData } = useQuery(ETH_PRICE_QUERY)
  const [ethPriceUsd, setEthPriceUsd] = useState<EthPrice>({
    loading: true,
    value: null,
  })

  // * Primary value is chainlink's ETH price
  // * If chainlink's ETH price is not available, use the ETH price from Uniswap's API
  useAsyncEffect(async () => {
    const chainlinkEthPrice = await fetch('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=ETH&tsyms=USD')
    if (!chainlinkEthPrice.ok) {
      setEthPriceUsd({ loading: false, value: null })
      return
    }
    const chainlinkEthPriceJson = await chainlinkEthPrice.json()
    setEthPriceUsd({ loading: false, value: new BigNumber(chainlinkEthPriceJson.RAW.ETH.USD.PRICE) })
  }, [])

  useEffect(() => {
    if (ethPriceUsd.loading) return
    if (ethPriceUsd.value) return
    if (!ethPriceData) return
    setEthPriceUsd({ loading: false, value: new BigNumber(ethPriceData.bundles[0].ethPrice) })
  }, [ethPriceData, ethPriceUsd.value, ethPriceUsd.loading])

  return {
    loading,
    error,
    ethPriceUsd: ethPriceUsd.value,
  }
}
