import classNames from 'classnames/bind'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { KBreederManager } from 'src/classes/KBreederManager'
import { ALLOWED_POOLS } from 'src/constants'

import { PoolInfo } from '../../components/PoolInfo/PoolInfo'
import styles from './PoolInfoPage.module.scss'
const cx = classNames.bind(styles)

interface PoolInfoPageProps {
  KBManager?: KBreederManager
  refreshUiInfo: number
  setRefreshUiInfo: (currValue: number) => void
}

export const PoolInfoPage: FunctionComponent<PoolInfoPageProps> = ({ KBManager, refreshUiInfo, setRefreshUiInfo }) => {
  const { PID } = useParams()

  if (!PID || !ALLOWED_POOLS.includes(PID)) {
    return <Navigate to={'/'} />
  }

  const stylesMainWrapper = cx('main__wrapper')
  const stylesPoolInfoWrapper = cx('pool__info__wrapper')

  return (
    <div className={stylesMainWrapper}>
      <div className={stylesPoolInfoWrapper}>
        <PoolInfo
          KBManager={KBManager}
          PID={parseInt(PID)}
          refreshUiInfo={refreshUiInfo}
          setRefreshUiInfo={setRefreshUiInfo}
        />
      </div>
    </div>
  )
}
