import classNames from 'classnames/bind'
import React, { FunctionComponent } from 'react'

import { DownArrowImage } from '../../../assets/svg/any/DownArrowImage'
import { WalletLogo } from '../../../assets/svg/Wallet/WalletLogo'
import { Button } from '../Button'
import styles from './WalletButton.module.scss'

const cx = classNames.bind(styles)
interface walletButtonProps {
  size: string
  noLabel?: boolean
  style?: string
  onClickConnectWallet: () => void
}

export const WalletButton: FunctionComponent<walletButtonProps> = ({
  size,
  // refreshRubicSdkConnection,
  // didUserDisconnect,
  noLabel,
  style,
  onClickConnectWallet,
}) => {
  const stylesWalletLogo = cx('wallet__logo')
  console.log(style)

  const onLogin = () => {}
  return (
    <Button onClick={onClickConnectWallet} type={cx('btn__wallet')} size={`${size} ${style}`}>
      <>
        <WalletLogo className={stylesWalletLogo} />
        {!noLabel && 'Connect wallet'}
        <DownArrowImage />
      </>
    </Button>
  )
}
