import './SelectWalletConnection.scss'

import React, { FunctionComponent, useRef } from 'react'

import { useClickOutside } from '../../hooks/useClickOutside'
import { Button } from '../buttons/Button'
import { PromptSwapX } from '../prompts/PromptSwapX'

interface SelectWalletConnectionProps {
  onClose: () => void
  onSelectMetaMask: () => void
  onSelectWalletConnect: () => void
}

export const SelectWalletConnection: FunctionComponent<SelectWalletConnectionProps> = ({
  onClose,
  onSelectMetaMask,
  onSelectWalletConnect,
}) => {
  const promptRef = useRef<HTMLInputElement>(null)
  useClickOutside(promptRef, onClose)
  // @ts-ignore
  const windowEthereumExists = typeof window.ethereum !== 'undefined'
  return (
    <div className="select_wallet_connection__prompt__cover">
      <PromptSwapX
        title="Connect a wallet"
        onClickClose={onClose}
        refference={promptRef}
        body={
          <>
            <div className="select_wallet_connection__prompt__body">
              {windowEthereumExists ? (
                <section className="select_wallet_connection__prompt__body__button_container">
                  <Button onClick={onSelectMetaMask} type={'btn__wallet'} size={'btn__large'}>
                    <>
                      <div className="select_wallet_connection__prompt__body__button_container__provider_container">
                        <p>MetaMask</p>
                        <img src={'/img/walletProviders/metamask.png'} alt={'MetaMask'} />
                      </div>
                    </>
                  </Button>
                </section>
              ) : (
                ''
              )}

              <section className="select_wallet_connection__prompt__body__button_container">
                <Button onClick={onSelectWalletConnect} type={'btn__wallet'} size={'btn__large'}>
                  <>
                    <div className="select_wallet_connection__prompt__body__button_container__provider_container">
                      <p>WalletConnect</p>
                      <img src={'/img/walletProviders/walletconnect.svg'} alt={'WalletConnect'} />
                    </div>
                  </>
                </Button>
              </section>
            </div>
          </>
        }
      />
    </div>
  )
}
