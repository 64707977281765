import './Button.scss'

interface IButton {
  type?: string
  size?: string
  image?: string
  children: JSX.Element
  arrow?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const Button = ({ type, size, onClick, children }: IButton) => {
  type = type ?? 'btn__primary'
  size = size ?? 'btn__md'

  return (
    <a className={'btn btn__prepend_icon btn__primary btn__wallet ' + size} onClick={onClick}>
      {children}
    </a>
  )
}
