import './Loader.scss'

import { FunctionComponent } from 'react'

interface LoaderProps {
  size?: string
}

export const Loader: FunctionComponent<LoaderProps> = ({ size }) => {
  const finalSize = size ? size : '1rem'
  return <div className="loader" style={{ width: finalSize, height: finalSize }}></div>
}
