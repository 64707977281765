import BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'
import { FunctionComponent, useState } from 'react'
import useAsyncEffect from 'use-async-effect'

import { KBreederManager } from '../../classes/KBreederManager'
import { StakingOptions } from '../../components/StakingOptions/StakingOptions'
import { TotalValueLocked } from '../../components/TotalValueLocked/TotalValueLocked'
import { YieldInfo } from '../../components/YiealdInfo/YieldInfo'
import { stakingPool } from '../../types/types'
import { stakingPools } from '../../variables/stakingPools'
import styles from './StakingOptionsPage.module.scss'

const cx = classNames.bind(styles)

interface StakingOptionsPageProps {
  KBManager: KBreederManager | undefined
  refreshUiInfo: number
}

// import { stakingOptions } from 'src/components/StakingOptions/StakingOptions'
export const StakingOptionsPage: FunctionComponent<StakingOptionsPageProps> = ({ KBManager, refreshUiInfo }) => {
  const stylesPageContainer = cx('staking__options__page')
  const stylesTvlYieldContainer = cx('tvl__yield__container')

  const [stakingPoolsAll, setStakingPoolsAll] = useState<Array<stakingPool>>([...stakingPools])

  const [totalYield, setTotalYield] = useState<BigNumber | undefined>()

  useAsyncEffect(async () => {
    if (!KBManager) {
      setTotalYield(undefined)
      setStakingPoolsAll([...stakingPools])
      return
    }

    const totalYield = await KBManager.userTotalYield()
    setTotalYield(totalYield)

    const totalStaked = await KBManager.totalStaked()
    const allStakingOptionsPrice = await KBManager.allStakingOptionsPriceUsd()
    const dkumaPriceUsd = allStakingOptionsPrice.find((pool) => pool.pid === 0)?.price
    const allPoolsAllocPoint = await KBManager.allPoolsAllocPoint()
    setStakingPoolsAll((currValue) => {
      return [
        ...currValue.map((currPool) => {
          const staked = totalStaked.find((pool) => pool.pid === currPool.PID)?.totalStaked ?? null
          const price = allStakingOptionsPrice.find((pool) => pool.pid === currPool.PID)?.price ?? null
          const value = staked && price ? staked.multipliedBy(price) : null
          const allocPoint = allPoolsAllocPoint.find((pool) => pool.pid === currPool.PID)?.allocPoint
          let apr: BigNumber | null = null
          // let apr: BigNumber | null = null
          if ((value?.isFinite() || value?.isEqualTo(0)) && dkumaPriceUsd?.isFinite() && allocPoint?.isFinite()) {
            apr = KBManager.getFarmApr(allocPoint, dkumaPriceUsd, value)
            // if (apr !== null) apr = aprToApr(apr, 48)
          }

          return {
            ...currPool,
            apr,
            total: {
              staked,
              value,
            },
            pricePerUnit: price,
          }
        }),
      ]
    })
  }, [KBManager, refreshUiInfo])

  const stakingPoolsWithValue = stakingPoolsAll.filter((pool) => pool.total?.value && !pool.unactive)
  let poolsValue
  let tvl

  if (stakingPoolsWithValue) {
    poolsValue = stakingPoolsWithValue.map((pool) => pool?.total?.value)
    if (poolsValue?.length > 0) tvl = poolsValue?.reduce((prevVal, currVal) => prevVal?.plus(currVal ?? 0))
  }

  return (
    <div className={stylesPageContainer}>
      <div className={cx('staking__options__wrapper')}>
        <div className={stylesTvlYieldContainer}>
          <TotalValueLocked value={tvl ?? undefined} />
          <YieldInfo value={totalYield} />
        </div>
        <StakingOptions KBManager={KBManager} stakingPoolsAll={stakingPoolsAll} />
      </div>
    </div>
  )
}
