import { WalletProvider } from '../../types/types'
import { createEthereumProvider } from './createEthereumProvider'
// import { createWalletConnectProvider } from './createWalletConnectProvider'

export const getWalletProvider = async (): Promise<WalletProvider | null> => {
  try {
    return await createEthereumProvider()
  } catch (error) {
    console.log('---getWalletProvider error', error)
    return null
  }
}
