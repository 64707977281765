import { Web3Provider } from '@ethersproject/providers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ProviderRpcError, WalletProvider } from 'src/common/walletConnection/types/types'
import Web3 from 'web3'

import { getWalletConnectProvider } from '../common/functions/utils'
import { makeObservable } from '../common/MakeObservable'

export interface WalletConnectProviderController {
  name: 'MetaMask' | 'WalletConnect' | ''
  provider: Web3Provider | WalletProvider | null
  ethereum: any | null
  address: string | ''
  chainId: string | ''
}

const walletConnectProviderStore = makeObservable<WalletConnectProviderController>({
  name: '',
  provider: null,
  ethereum: null,
  address: '',
  chainId: '',
})
export const useWalletConnectProviderController = () => {
  const [walletConnectProviderController, setWalletConnector] = useState<WalletConnectProviderController>(
    walletConnectProviderStore.get()
  )

  const onAccountChange = async (accounts: string[]) => {
    const controller: WalletConnectProviderController = walletConnectProviderStore.get()
    if (accounts.length === 0) {
      await handleDisconnect()
    } else {
      walletConnectProviderStore.set({ ...controller, address: accounts[0] })
    }
    console.log('---onAccountChange', accounts, walletConnectProviderStore.get())
  }

  const onChainChanged = async (chainId: number | string) => {
    const controller: WalletConnectProviderController = walletConnectProviderStore.get()
    const chainIdUnknown = `${chainId}`
    let newChainId: string
    if (chainIdUnknown.includes('x')) {
      const chainIdNum = parseInt(chainIdUnknown, 16)
      newChainId = `${chainIdNum}`
    } else {
      newChainId = `${chainId}`
    }
    // Need to set new providers in order to make it reload breeder data
    // when changing chain
    if (controller.name === 'WalletConnect') {
      const connectProvider = getWalletConnectProvider()
      await connectProvider.enable()
      const web3 = new Web3()
      web3.setProvider(connectProvider as any)
      walletConnectProviderStore.set({
        ...controller,
        chainId: newChainId,
      })
    } else {
      walletConnectProviderStore.set({
        ...controller,
        chainId: newChainId,
        // @ts-ignore
        provider: new Web3Provider(window.ethereum),
      })
    }

    console.log('---onChainChanged', chainId, newChainId, controller)
  }

  const onDisconnect = ({ message, code, data = '' }: ProviderRpcError) => {
    const controller: WalletConnectProviderController = walletConnectProviderStore.get()
    walletConnectProviderStore.set({
      ...controller,
      name: '',
      provider: null,
      ethereum: null,
      address: '',
      chainId: '',
    })
    console.log('---onDisconnect', controller, code, message, data)
  }

  useEffect(() => {
    return walletConnectProviderStore.subscribe(setWalletConnector)
  }, [walletConnectProviderController, setWalletConnector])

  const setNewWalletConnectProviderController = useCallback(
    async (providerController: WalletConnectProviderController) => {
      walletConnectProviderStore.set(providerController)
      console.log('---setNewWalletConnectProviderController', providerController)
      if (providerController === null) {
        return
      }
      if (providerController.name === 'WalletConnect' && providerController.provider) {
        const provider = providerController.provider as WalletProvider
        provider.on('accountsChanged', onAccountChange)
        provider.on('chainChanged', onChainChanged)
        provider.on('disconnect', onDisconnect)
      }
      if (providerController.name === 'MetaMask' && providerController.ethereum) {
        providerController.ethereum.on('accountsChanged', onAccountChange)
        providerController.ethereum.on('chainChanged', onChainChanged)
        providerController.ethereum.on('disconnect', onDisconnect)
      }
    },
    [setWalletConnector]
  )

  const handleAddChain = async (
    networkConnectionInfoList: Array<{
      chainId: string
      chainName: string
      rpcUrls: [string]
    } | null>
  ) => {
    const controller: WalletConnectProviderController = walletConnectProviderStore.get()
    console.log('--handleAddChain', controller)
    try {
      // @ts-ignore
      await controller.provider.request({
        method: 'wallet_addEthereumChain',
        params: networkConnectionInfoList,
      })
    } catch (addError) {
      // handle "add" error
      console.log('Failed adding chain to wallet ', networkConnectionInfoList)
    }
  }

  const shouldTryToAddChainToWallet = (switchError: any): boolean => {
    const metaMaskFlag = switchError && switchError.code && (switchError.code === 4902 || switchError.code === -32603)
    const walletConnectFlag =
      switchError && new RegExp('Try adding the chain using wallet_addEthereumChain first').test(switchError.message)
    return metaMaskFlag || walletConnectFlag
  }
  /**
   * WalletConnect uses chain ids as numbers, but MetaMask Chrome extension uses hex ids
   */
  const handleSwitchChain = async (
    hexChainId: string,
    chainIdNum: number,
    networkConnectionInfoList: Array<{
      chainId: string
      chainName: string
      rpcUrls: [string]
    } | null>
  ) => {
    const controller: WalletConnectProviderController = walletConnectProviderStore.get()
    console.log('--handleSwitchChain', controller)
    try {
      if (controller.name === 'WalletConnect') {
        if (!controller.provider) {
          console.log('Not able to switch network when no provider is set')
          return
        }
        const provider = controller.provider as WalletProvider
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        })
      }
      if (controller.name === 'MetaMask') {
        if (!controller.ethereum) {
          console.log('Not able to switch network when no ethereum instance is set')
          return
        }
        controller.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        })
      }
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to the
      // wallet before.
      if (shouldTryToAddChainToWallet(switchError)) {
        await handleAddChain(networkConnectionInfoList)
      }
    }
  }
  const handleDisconnect = async () => {
    const controller: WalletConnectProviderController = walletConnectProviderStore.get()
    console.log('--handleDisconnect', controller)

    if (controller.name === 'MetaMask') {
      walletConnectProviderStore.set({
        ...controller,
        name: '',
        provider: null,
        ethereum: null,
        address: '',
        chainId: '',
      })
    } else if (controller.name === 'WalletConnect') {
      if (controller.provider) {
        try {
          const provider = controller.provider as WalletProvider
          await provider.disconnect()
        } catch (err) {
          console.error(err)
        }
      }
      walletConnectProviderStore.set({
        ...controller,
        name: '',
        provider: null,
        ethereum: null,
        address: '',
        chainId: '',
      })
    }
  }

  const walletConnectProviderActions = useMemo(() => {
    return {
      switchChain: handleSwitchChain,
      addChain: handleAddChain,
      disconnect: handleDisconnect,
    }
  }, [walletConnectProviderController])

  return {
    walletConnectProviderController,
    setNewWalletConnectProviderController,
    walletConnectProviderActions,
  }
}
