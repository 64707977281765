interface DownArrowImageProps {
  style?: string
}

export const DownArrowImage = ({ style }: DownArrowImageProps) => {
  return (
    <svg
      className={'btn__down__arrow ' + [style]}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
        <path d="M330 3767 c-19 -12 -43 -37 -53 -55 -22 -38 -26 -116 -7 -153 20 -40 2172 -2189 2212 -2210 47 -24 99 -24 146 0 49 26 2208 2187 2222 2224 27 73 -8 170 -74 201 -44 21 -108 21 -153 0 -20 -10 -452 -438 -1048 -1038 l-1015 -1021 -1027 1026 c-566 565 -1038 1032 -1050 1038 -38 19 -116 13 -153 -12z" />
      </g>
    </svg>
  )
}
