import { stakingPool } from '../types/types'

export const stakingPools: Array<stakingPool> = [
  {
    name: 'KUMA V2',
    PID: 12,
    contract: '0x48C276e8d03813224bb1e55F953adB6d02FD3E02',
    image: {
      1: require('src/assets/img/tokens/kuma/logo.png'),
    },
  },
  {
    name: 'KUMA V2_ETH',
    PID: 13,
    contract: '0xDF60E6416Fcf8C955FdDF01148753A911F7A5905',
    image: {
      1: require('src/assets/img/tokens/kuma/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 12,
      token1Contract: '0x48C276e8d03813224bb1e55F953adB6d02FD3E02',
    },
  },
  {
    name: 'dKUMA_ETH',
    PID: 6,
    contract: '0xB4EdfeC7Aa5588786901C63A8338e4b37611B2Af',
    image: {
      1: require('src/assets/img/tokens/dkuma/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 0,
      token1Contract: '0x3f5dd1A1538a4F9f82E543098f01F22480B0A3a8',
    },
  },
  {
    name: 'SHIB',
    PID: 2,
    contract: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
    image: {
      1: require('src/assets/img/tokens/shiba/logo.png'),
    },
  },
  {
    name: 'SHIB_ETH',
    PID: 8,
    contract: '0x811beEd0119b4AfCE20D2583EB608C6F7AF1954f',
    image: {
      1: require('src/assets/img/tokens/shiba/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 2,
      token1Contract: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
    },
  },
  {
    name: 'LEASH',
    PID: 3,
    contract: '0x27C70Cd1946795B66be9d954418546998b546634',
    image: {
      1: require('src/assets/img/tokens/leash/logo.png'),
    },
  },
  {
    name: 'LEASH_ETH',
    PID: 9,
    contract: '0x874376Be8231DAd99AABF9EF0767b3cc054c60eE',
    image: {
      1: require('src/assets/img/tokens/leash/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 3,
      token1Contract: '0x27C70Cd1946795B66be9d954418546998b546634',
    },
  },
  {
    name: 'AKITA',
    PID: 4,
    contract: '0x3301Ee63Fb29F863f2333Bd4466acb46CD8323E6',
    image: {
      1: require('src/assets/img/tokens/akita/logo.png'),
    },
  },
  {
    name: 'AKITA_ETH',
    PID: 10,
    contract: '0xDA3A20aaD0C34fA742BD9813d45Bbf67c787aE0b',
    image: {
      1: require('src/assets/img/tokens/akita/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 4,
      token1Contract: '0x3301Ee63Fb29F863f2333Bd4466acb46CD8323E6',
    },
  },
  {
    name: 'ELON',
    PID: 5,
    contract: '0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3',
    image: {
      1: require('src/assets/img/tokens/elon/logo.png'),
    },
  },
  {
    name: 'ELON_ETH',
    PID: 11,
    contract: '0x7B73644935b8e68019aC6356c40661E1bc315860',
    image: {
      1: require('src/assets/img/tokens/elon/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 5,
      token1Contract: '0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3',
    },
  },
  {
    name: 'dKUMA',
    PID: 0,
    contract: '0x3f5dd1A1538a4F9f82E543098f01F22480B0A3a8',
    image: {
      1: require('src/assets/img/tokens/dkuma/logo.png'),
    },
    unactive: true,
  },
]

export const stakingPoolsAll: Array<stakingPool> = [
  {
    name: 'dKUMA',
    PID: 0,
    contract: '0x3f5dd1A1538a4F9f82E543098f01F22480B0A3a8',
    image: {
      1: require('src/assets/img/tokens/dkuma/logo.png'),
    },
  },
  {
    name: 'KUMA V2',
    PID: 12,
    contract: '0x48C276e8d03813224bb1e55F953adB6d02FD3E02',
    image: {
      1: require('src/assets/img/tokens/kuma/logo.png'),
    },
  },
  {
    name: 'SHIB',
    PID: 2,
    contract: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
    image: {
      1: require('src/assets/img/tokens/shiba/logo.png'),
    },
  },
  {
    name: 'LEASH',
    PID: 3,
    contract: '0x27C70Cd1946795B66be9d954418546998b546634',
    image: {
      1: require('src/assets/img/tokens/leash/logo.png'),
    },
  },
  {
    name: 'AKITA',
    PID: 4,
    contract: '0x3301Ee63Fb29F863f2333Bd4466acb46CD8323E6',
    image: {
      1: require('src/assets/img/tokens/akita/logo.png'),
    },
  },
  {
    name: 'ELON',
    PID: 5,
    contract: '0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3',
    image: {
      1: require('src/assets/img/tokens/elon/logo.png'),
    },
  },
  {
    name: 'dKUMA_ETH',
    PID: 6,
    contract: '0xB4EdfeC7Aa5588786901C63A8338e4b37611B2Af',
    image: {
      1: require('src/assets/img/tokens/dkuma/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 0,
      token1Contract: '0x3f5dd1A1538a4F9f82E543098f01F22480B0A3a8',
    },
  },
  {
    name: 'KUMA V2_ETH',
    PID: 13,
    contract: '0xDF60E6416Fcf8C955FdDF01148753A911F7A5905',
    image: {
      1: require('src/assets/img/tokens/kuma/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 12,
      token1Contract: '0x48C276e8d03813224bb1e55F953adB6d02FD3E02',
    },
  },
  {
    name: 'SHIBA_ETH',
    PID: 8,
    contract: '0x811beEd0119b4AfCE20D2583EB608C6F7AF1954f',
    image: {
      1: require('src/assets/img/tokens/shiba/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 2,
      token1Contract: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
    },
  },
  {
    name: 'LEASH_ETH',
    PID: 9,
    contract: '0x874376Be8231DAd99AABF9EF0767b3cc054c60eE',
    image: {
      1: require('src/assets/img/tokens/leash/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 3,
      token1Contract: '0x27C70Cd1946795B66be9d954418546998b546634',
    },
  },
  {
    name: 'AKITA_ETH',
    PID: 10,
    contract: '0xDA3A20aaD0C34fA742BD9813d45Bbf67c787aE0b',
    image: {
      1: require('src/assets/img/tokens/akita/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 4,
      token1Contract: '0x3301Ee63Fb29F863f2333Bd4466acb46CD8323E6',
    },
  },
  {
    name: 'ELON_ETH',
    PID: 11,
    contract: '0x7B73644935b8e68019aC6356c40661E1bc315860',
    image: {
      1: require('src/assets/img/tokens/elon/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 5,
      token1Contract: '0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3',
    },
  },
  {
    name: 'KUMA V1',
    PID: 1,
    contract: '0xb525Ecee288B99216CD481C56b6EFbdbE9bF90b5',
    image: {
      1: require('src/assets/img/tokens/kuma/logo.png'),
    },
  },
  {
    name: 'KUMA V1_ETH',
    PID: 7,
    contract: '0x27Fd2F5942049BCD601428c7bceDE364180B4B3F',
    image: {
      1: require('src/assets/img/tokens/kuma/logo.png'),
      2: require('src/assets/img/tokens/ethereum/logo.png'),
    },
    lp: {
      isLpToken: true,
      token1Pid: 1,
      token1Contract: '0xb525Ecee288B99216CD481C56b6EFbdbE9bF90b5',
    },
  },
]
