import './assets/scss/main.scss'

// import { Contract } from '@ethersproject/contracts'
import { Web3Provider } from '@ethersproject/providers'
// import BN from 'bn.js'
// import BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import useAsyncEffect from 'use-async-effect'
import Web3 from 'web3'

import styles from './App.module.scss'
import { KBreederManager } from './classes/KBreederManager'
import { wildcardSettingsInt } from './common/models/Wildcard'
import { defaultWildcardSettings } from './common/variables/Wildcard'
import { getWalletProvider } from './common/walletConnection/functions/provider/getWalletProvider'
import { WalletProvider } from './common/walletConnection/types/types'
import { EnforceEthChain } from './components/EnforceEthChain/EnforceEthChain'
import { Header } from './components/Header/Header'
import { AnimatePrompt } from './components/prompts/AnimatePrompt'
import { SelectWalletConnection } from './components/SelectWalletConnection/SelectWalletConnection'
import { WildcardDiv } from './components/wildcard/WildcardDiv'
import { useClickOutside } from './hooks/useClickOutside'
import { useGetEthPriceUsd } from './hooks/useGetEthPrice'
import {
  useWalletConnectProviderController,
  WalletConnectProviderController,
} from './hooks/useWalletConnectProviderController'
import { PoolInfoPage } from './pages/PoolInfoPage/PoolInfoPage'
import { StakingOptionsPage } from './pages/StakingOptions/StakingOptionsPage'

const cx = classNames.bind(styles)

function App() {
  const [KBManager, setKBManager] = useState<KBreederManager | undefined>()
  const [refreshUiInfo, setRefreshUiInfo] = useState<number>(0)
  const { ethPriceUsd } = useGetEthPriceUsd()

  useEffect(() => {
    const refreshUi = setInterval(() => {
      setRefreshUiInfo((currValue) => (currValue += 1))
    }, 20000)

    return () => clearInterval(refreshUi)
  })

  useEffect(() => {
    if (!ethPriceUsd) return
    if (!KBManager) return
    KBManager.setEthPriceUsd = ethPriceUsd
    setRefreshUiInfo((currValue) => currValue + 1)
  }, [ethPriceUsd])

  // Wildcard settings start
  const [wildcardSettings, setWildcardSettings] = useState<wildcardSettingsInt>(defaultWildcardSettings)
  const appInitialized = useRef<boolean>(false)

  const wildcardComponentHasPromptRef = useRef<boolean | undefined>(false)
  wildcardComponentHasPromptRef.current = wildcardSettings.componentHasPrompt
  const handleWildcardClickOutside = () => {
    if (wildcardComponentHasPromptRef.current) return
    setWildcardSettings({ ...defaultWildcardSettings })
  }
  const wildcardRef = useRef(null)
  useClickOutside(wildcardRef, handleWildcardClickOutside)
  const exitDuration = wildcardSettings.exitDuration
  useEffect(() => {
    if (wildcardSettings.display) document.body.style.overflowY = 'hidden'
    if (!wildcardSettings.display) document.body.style.overflowY = 'unset'
  }, [wildcardSettings.display])
  // Wildcard settings end
  const { walletConnectProviderController, setNewWalletConnectProviderController, walletConnectProviderActions } =
    useWalletConnectProviderController()

  // Account and provider management start
  const handleCreateMetamaskSession = async (isManualClick: boolean) => {
    try {
      setWildcardSettings(defaultWildcardSettings)
      await setNewWalletConnectProviderController({ ...walletConnectProviderController, name: 'MetaMask' })

      // @ts-ignore
      if (typeof window.ethereum === 'undefined') {
        // Cannot connect to metamask if no window.ethereum instance
        await walletConnectProviderActions.disconnect()
        return
      }

      let accounts: string[]
      if (isManualClick) {
        // @ts-ignore
        accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
      } else {
        // @ts-ignore
        accounts = await window.ethereum.request({
          method: 'eth_accounts',
        })
      }
      if (!accounts.length) {
        // Cannot connect to metamask if no accounts are existing
        await walletConnectProviderActions.disconnect()
        return
      }

      const address = accounts[0]
      // @ts-ignore
      const chainIdHex = await window.ethereum.request({
        method: 'eth_chainId',
      })
      const updatedController: WalletConnectProviderController = {
        ...walletConnectProviderController,
        name: 'MetaMask',
        //@ts-ignore
        provider: new Web3Provider(window.ethereum),
        //@ts-ignore
        ethereum: window.ethereum,
        address,
        chainId: `${chainIdHex}`,
      }

      console.log('updatedController', updatedController)
      await setNewWalletConnectProviderController(updatedController)
    } catch (error) {
      console.error('Error [handleCreateMetamaskSession]: ', error)
    }
  }

  const handleCreateWalletConnectSession = async () => {
    await setNewWalletConnectProviderController({ ...walletConnectProviderController, name: 'WalletConnect' })
    setWildcardSettings(defaultWildcardSettings)
    const connectProvider = await getWalletProvider()

    if (connectProvider === null) {
      return
    }

    try {
      const [address] = await connectProvider.enable()
      const web3 = new Web3()
      web3.setProvider(connectProvider as any)
      const chainId = connectProvider.chainId
      const updatedController: WalletConnectProviderController = {
        ...walletConnectProviderController,
        name: 'WalletConnect',
        provider: connectProvider,
        address,
        chainId: chainId.toString(),
      }
      await setNewWalletConnectProviderController(updatedController)
    } catch (err) {
      console.error(err)
      await walletConnectProviderActions.disconnect()
    }
  }

  const handleDisconnectClick = useCallback(async () => {
    setWildcardSettings({ ...defaultWildcardSettings })
    await walletConnectProviderActions.disconnect()
    setKBManager(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickConnectWallet = useCallback(async () => {
    setWildcardSettings({
      display: true,
      componentName: 'SelectWalletConnection',
      Component: (
        <SelectWalletConnection
          onClose={() => setWildcardSettings(defaultWildcardSettings)}
          onSelectMetaMask={() => handleCreateMetamaskSession(true)}
          onSelectWalletConnect={handleCreateWalletConnectSession}
        />
      ),
      background: 'normal',
    })
  }, [])

  // Handle laoding wallet on init
  const hasWalletConnectConnection = async (): Promise<boolean> => {
    // return false
    const connectProvider = await getWalletProvider()
    if (connectProvider == null) return false
    let expiry = connectProvider.signer.session?.expiry
    if (expiry == null) return false
    expiry = expiry * 1000
    return expiry > Date.now()
  }
  useEffect(() => {
    if (!appInitialized.current) {
      handleAppInitialization()
    }
    return () => {
      appInitialized.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkAndHandleChainChange = async (chainId: string) => {
    if (chainId && chainId !== '0x1' && chainId !== '1') {
      setWildcardSettings({
        display: true,
        componentName: 'EnforceEthChain',
        componentHasPrompt: true,
        Component: (
          <EnforceEthChain
            onClickDisconnectWallet={handleDisconnectClick}
            onClickedSwitchToEthChain={handleSwitchToEthChain}
          />
        ),
        background: 'normal',
      })
    } else if ((chainId === '0x1' || chainId === '1') && wildcardSettings.componentName === 'EnforceEthChain') {
      setWildcardSettings(defaultWildcardSettings)
      await handleRefreshKBreederManager()
    }
  }
  const handleSwitchToEthChain = useCallback(() => {
    const hexChainId = '0x1'
    const networkConnectionInfoList: Array<{
      chainId: string
      chainName: string
      rpcUrls: [string]
    }> = [
      {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        rpcUrls: ['https://mainnet.infura.io/v3/'],
      },
    ]
    walletConnectProviderActions.switchChain(hexChainId, 1, networkConnectionInfoList)
  }, [])
  // Account and provider management end
  // Test loading contract data

  useAsyncEffect(async () => {
    if (walletConnectProviderController.chainId) {
      await checkAndHandleChainChange(walletConnectProviderController.chainId)
    }
  }, [walletConnectProviderController.chainId])

  useAsyncEffect(async () => {
    await handleRefreshKBreederManager()
  }, [walletConnectProviderController.address])

  const handleRefreshKBreederManager = async () => {
    console.log('walletConnectProviderController.provider', walletConnectProviderController.provider)
    if (walletConnectProviderController.provider) {
      const provider: Web3Provider =
        walletConnectProviderController.name === 'WalletConnect'
          ? new Web3Provider(walletConnectProviderController.provider as WalletProvider)
          : (walletConnectProviderController.provider as Web3Provider)
      const newKBManager = new KBreederManager(walletConnectProviderController.address, provider, ethPriceUsd)
      setKBManager(newKBManager)
    }
  }
  // test end

  const handleAppInitialization = async () => {
    const isWalletConnectConnected = await hasWalletConnectConnection()
    if (isWalletConnectConnected) {
      await handleCreateWalletConnectSession()
    } else {
      await handleCreateMetamaskSession(false)
    }
  }

  return (
    <div className="App">
      <AnimatePresence>
        {wildcardSettings.display && wildcardSettings.Component && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { type: 'tween', duration: exitDuration ?? 0.3 } }}
            className={
              cx('cover__all')
              // 'cover__all ' +
              // // 'no__visible__to__visible ' +
              // [
              //   wildcardSettings.background === 'normal'
              //     ? 'cover__all-normal'
              //     : `cover__all-${wildcardSettings.background}`,
              // ]
            }
            key="wildcardCover"
          >
            <div ref={wildcardRef} className="wildcardRef">
              <AnimatePrompt key="wildcard" exitDuration={exitDuration}>
                <WildcardDiv Component={wildcardSettings.Component} />
              </AnimatePrompt>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <Router>
        <Header
          setWildcardSettings={setWildcardSettings}
          onDisconnect={handleDisconnectClick}
          onClickConnectWallet={handleClickConnectWallet}
        />
        <div className={cx('main__wrapper')}>
          <Routes>
            <Route path="/" element={<StakingOptionsPage KBManager={KBManager} refreshUiInfo={refreshUiInfo} />} />
            <Route
              path="pool/:PID"
              element={
                <PoolInfoPage KBManager={KBManager} refreshUiInfo={refreshUiInfo} setRefreshUiInfo={setRefreshUiInfo} />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </div>
  )
}

export default App
