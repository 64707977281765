import classNames from 'classnames/bind'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { NavLink } from 'react-router-dom'
import { BurgerIcon } from 'src/assets/svg/any/BurgerIcon'

import { CrossCloseRound } from '../../assets/svg/any/CrossClose'
import { DownArrowImage } from '../../assets/svg/any/DownArrowImage'
import { KumaBreederLogo } from '../../assets/svg/KumaBreederLogo/KumaBreederLogo'
import { WalletLogo } from '../../assets/svg/Wallet/WalletLogo'
import { formatAddress } from '../../common/functions/utils'
import { wildcardSettingsInt } from '../../common/models/Wildcard'
import { defaultWildcardSettings } from '../../common/variables/Wildcard'
import { useClickOutside } from '../../hooks/useClickOutside'
import { useWalletConnectProviderController } from '../../hooks/useWalletConnectProviderController'
import { AccountDetails } from '../AccountDetails/AccountDetails'
import { WalletButton } from '../buttons/WalletButton/WalletButton'
import styles from './Header.module.scss'

const cx = classNames.bind(styles)
interface HeaderProps {
  onClickConnectWallet: () => void
  setWildcardSettings: Dispatch<SetStateAction<wildcardSettingsInt>>
  onDisconnect: () => void
}

export const Header: FunctionComponent<HeaderProps> = ({ onClickConnectWallet, setWildcardSettings, onDisconnect }) => {
  const stylesAddress = cx('address__container__address')
  const stylesAddressWalletLogo = cx('address__container__walletLogo')
  const { walletConnectProviderController } = useWalletConnectProviderController()
  const wildcardAccountDetails = {
    display: true,
    componentName: 'AccountDetails',
    Component: (
      <AccountDetails
        address={walletConnectProviderController.address}
        onClose={() => setWildcardSettings(defaultWildcardSettings)}
        onDisconnect={onDisconnect}
      />
    ),
    background: 'normal',
  }
  const [displayBurguerMenu, setDisplayBurguerMenu] = useState<boolean>(false)
  const burgerMenuRef = useRef(null)
  useClickOutside(burgerMenuRef, () => setDisplayBurguerMenu(false))

  useEffect(() => {
    if (displayBurguerMenu) document.body.style.overflowY = 'hidden'
    if (!displayBurguerMenu) document.body.style.overflowY = 'unset'
  }, [displayBurguerMenu])

  return (
    <nav className={cx('navbar')}>
      <NavLink to="/" className={cx('noUserSelect')}>
        <KumaBreederLogo className={cx('kumaBreeder__logo')} />
      </NavLink>
      <div className={cx('options__container')}>
        <ul>
          <NavLink to="/">Staking Pools</NavLink>
          <a className={cx('link__website')} href="https://www.kumatokens.com" target="_blank" rel="noreferrer">
            Website
          </a>
          <a className={cx('link__swapx')} href="https://swap.kumatokens.com" target="_blank" rel="noreferrer">
            Kuma SwapX
          </a>
          <a href="https://dbreeder.kumatokens.com" target="_blank" rel="noreferrer">
            dKuma Breeder
          </a>
        </ul>
        <div className={cx('mla')}>
          {walletConnectProviderController.address ? (
            <div
              className={cx('address__container')}
              onClick={() => {
                setWildcardSettings(wildcardAccountDetails)
              }}
            >
              <p className={stylesAddress}>{formatAddress(walletConnectProviderController.address)}</p>
              <span className={stylesAddressWalletLogo}>
                <WalletLogo />
              </span>
              <DownArrowImage />
            </div>
          ) : (
            <>
              <WalletButton onClickConnectWallet={onClickConnectWallet} size="btn__md__longer" />
            </>
          )}
        </div>
      </div>

      <div className={cx('mla')} onClick={() => setDisplayBurguerMenu(true)}>
        <BurgerIcon styles={cx('burguerMenu__button')} size={'3rem'} />
      </div>

      <AnimatePresence>
        {displayBurguerMenu && (
          <div className={cx('cover__all', { active: displayBurguerMenu })}>
            <motion.div
              className={cx('options__container__burguerMenu', { active: displayBurguerMenu })}
              ref={burgerMenuRef}
              initial={{ x: 300 }}
              animate={{ x: 0 }}
              exit={{ x: 300 }}
            >
              <CrossCloseRound
                size="2.2rem"
                styles={cx('options__container__burguerMenu__close')}
                onClick={() => setDisplayBurguerMenu(false)}
              />
              <ul>
                <NavLink to="/">Staking Pools</NavLink>
                <a className={cx('link__website')} href="https:/www.kumatokens.com" target="_blank" rel="noreferrer">
                  Website
                </a>
                <a className={cx('link__swapx')} href="https://swap.kumatokens.com" target="_blank" rel="noreferrer">
                  Kuma SwapX
                </a>
                <a href="https://dbreeder.kumatokens.com" target="_blank" rel="noreferrer">
                  dKuma Breeder
                </a>
              </ul>
              <div>
                {walletConnectProviderController.address ? (
                  <div
                    className={cx('address__container', 'address__container-burguerMenu')}
                    onClick={() => {
                      setDisplayBurguerMenu(false)
                      setWildcardSettings(wildcardAccountDetails)
                    }}
                  >
                    <p className={stylesAddress}>{formatAddress(walletConnectProviderController.address)}</p>
                    <span className={stylesAddressWalletLogo}>
                      <WalletLogo />
                    </span>
                    <DownArrowImage />
                  </div>
                ) : (
                  <>
                    <WalletButton
                      onClickConnectWallet={() => {
                        setDisplayBurguerMenu(false)
                        onClickConnectWallet()
                      }}
                      size="btn__md__longer"
                      noLabel={true}
                      style={cx('wallet__button__noLabel')}
                    />
                  </>
                )}
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </nav>
  )
}
