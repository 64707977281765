// import BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'
import { FunctionComponent, useEffect } from 'react'

import { KBreederManager } from '../../classes/KBreederManager'
import { stakingPool } from '../../types/types'
import styles from './StakingOptions.module.scss'
import { StakingOptionsButtonColumn } from './StakingOptionsButtonColumn/StakingOptionsButtonColumn'
import { StakingOptionsButtonTableRow } from './StakingOptionsButtonTableRow/StakingOptionsButtonTableRow'
const cx = classNames.bind(styles)

interface StakingOptionsProps {
  KBManager: KBreederManager | undefined
  stakingPoolsAll: Array<stakingPool>
}

export const StakingOptions: FunctionComponent<StakingOptionsProps> = ({ KBManager, stakingPoolsAll }) => {
  const stylesMainContainer = cx('staking__options__container')
  const stylesTableMainContainer = cx('table__main__wrapper')
  const stylesColumnNamesContainer = cx('table__head')
  const stylesTableRow = cx('table__row')
  const stylesTableRowClickable = cx('table__row', 'table__row-clickable')
  const stylesTableCell = cx('table__cell')
  const stylesTableCellBody = cx('table__cell', 'table__cell__body')
  const stylesButtonsContainer = cx('table__body')

  const stylesColumnButtonsContainer = cx('columnButtons__container')

  useEffect(() => {
    // update APY and Staked amount/value
  }, [])

  const activeStakingPools = stakingPoolsAll.filter((pool) => !pool.unactive)

  return (
    <div className={stylesMainContainer}>
      <div className={stylesTableMainContainer}>
        <div className={stylesColumnNamesContainer}>
          <div className={stylesTableRow}>
            <h4 className={stylesTableCell}>Pair</h4>
            <h4 className={stylesTableCell}>Token</h4>
            <h4 className={stylesTableCell}>APR</h4>
            <h4 className={stylesTableCell}>Total</h4>
          </div>
        </div>
        <div className={stylesButtonsContainer}>
          {activeStakingPools.map((pool) => (
            <StakingOptionsButtonTableRow
              key={pool.PID}
              PID={pool.PID}
              image1={pool.image[1]}
              image2={pool.image[2]}
              tokenName={pool.name}
              apr={pool.apr ?? undefined}
              totalStaked={pool.total?.staked ?? undefined}
              totalStakedValue={pool.total?.value ?? undefined}
              containerStyles={stylesTableRowClickable}
              cellStyles={stylesTableCellBody}
            />
          ))}
        </div>
      </div>
      <div className={stylesColumnButtonsContainer}>
        {activeStakingPools.map((pool) => (
          <StakingOptionsButtonColumn
            key={pool.PID}
            PID={pool.PID}
            image1={pool.image[1]}
            image2={pool.image[2]}
            tokenName={pool.name}
            apr={pool.apr ?? undefined}
            totalStaked={pool.total?.staked ?? undefined}
            totalStakedValue={pool.total?.value ?? undefined}
          />
        ))}
      </div>
    </div>
  )
}
