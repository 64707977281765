import BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'
import { FunctionComponent } from 'react'

import { StakingOptionsButton } from '../StakingOptionsButton/StakingOptionsButton'
import styles from './StakingOptionsButtonTableRow.module.scss'

const cx = classNames.bind(styles)

interface StakingOptionsButtonTableRowProps {
  PID: number
  image1: string
  image2?: string
  tokenName: string
  apr?: BigNumber
  totalStaked?: BigNumber
  totalStakedValue?: BigNumber
  containerStyles?: string
  cellStyles?: string
}

export const StakingOptionsButtonTableRow: FunctionComponent<StakingOptionsButtonTableRowProps> = ({
  PID,
  image1,
  image2,
  tokenName,
  apr,
  totalStaked,
  totalStakedValue,
  containerStyles,
  cellStyles,
}) => {
  const stylesHeightRow = cx('margin')
  return (
    <>
      <StakingOptionsButton
        PID={PID}
        image1={image1}
        image2={image2}
        tokenName={tokenName}
        apr={apr}
        totalStaked={totalStaked}
        totalStakedValue={totalStakedValue}
        containerStyles={containerStyles}
        cellStyles={cellStyles}
      />
      <div className={stylesHeightRow}></div>
    </>
  )
}
