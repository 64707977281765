import './AccountDetails.scss'

import { FunctionComponent, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { formatAddress } from '../../common/functions/utils'
import { useClickOutside } from '../../hooks/useClickOutside'
import { Button } from '../buttons/Button'
// @ts-ignore
import { PromptSwapX } from '../prompts/PromptSwapX'

interface AccountDetailsProps {
  address: string
  onClose: () => void
  onDisconnect: () => void
}

export const AccountDetails: FunctionComponent<AccountDetailsProps> = ({ address, onClose, onDisconnect }) => {
  const promptRef = useRef<HTMLInputElement>(null)
  useClickOutside(promptRef, onClose)

  const [hasCopiedWalletAddress, setHasCopiedWalletAddress] = useState<boolean>(false)

  const didCopyText = () => {
    setHasCopiedWalletAddress(true)
    setTimeout(() => {
      setHasCopiedWalletAddress(false)
    }, 1200)
  }

  const openExplorer = (walletAddress: string) => {
    // @ts-ignore
    if (typeof window.ethereum !== 'undefined') {
      window.open(`https://etherscan.io/address/${walletAddress}`, '_blank')
    }
  }
  return (
    <div className="account_details__prompt__cover">
      <PromptSwapX
        title="Account details"
        onClickClose={onClose}
        refference={promptRef}
        body={
          <>
            <div className="account_details__prompt__body">
              <div className="account_details__prompt__body__action_button_container">
                <Button type="btn__primary btn__primary-border" onClick={onDisconnect}>
                  <p>Disconnect</p>
                </Button>
              </div>
              <div className="account_details__prompt__body__wallet_text">
                <p>
                  <b>Wallet: </b> {formatAddress(address)}
                </p>
              </div>
              <section className="account_details__prompt__buttons">
                <CopyToClipboard text={address} onCopy={didCopyText}>
                  <div className="account_details__prompt__buttons__copy_address">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <p>{hasCopiedWalletAddress ? 'Copied' : 'Copy address'}</p>
                  </div>
                </CopyToClipboard>

                <div className="account_details__prompt__buttons__open_explorer" onClick={() => openExplorer(address)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                  <p>View on explorer</p>
                </div>
              </section>
            </div>
          </>
        }
      />
    </div>
  )
}
