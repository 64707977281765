import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner } from '@ethersproject/providers'
import { Web3Provider } from '@ethersproject/providers/src.ts/web3-provider'
import { BigNumber } from 'bignumber.js'
import BN from 'bn.js'
import { uniswapRouter02ABI } from 'src/ABI/uniswapRouter02ABI'
import { aprToApy } from 'src/common/functions/aprToApy'
import { BLOCKS_PER_YEAR, SUSHI_PER_YEAR, TOTAL_ALLOCATION_POINT } from 'src/constants'
import Web3 from 'web3'

import { ABI, tokensABI } from '../ABI/abi'
import uniswapV2PairABI from '../ABI/uniswapV2PairABI.json'
import { stakingPools } from '../variables/stakingPools'

const KBreederContract = process.env.REACT_APP_KBREEDER_CONTRACT as string
const uniswapRouter02Contract = process.env.REACT_APP_UNISWAP_ROUTER_02_CONTRACT as string
// const infuraProvider = process.env.REACT_APP_ETH_RPC as string
const wethContract = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'

export class KBreederManager {
  private _userAddress: string
  private _ethPriceUsd: BigNumber | undefined
  private _signer: JsonRpcSigner
  private _readOnlyContract: Contract
  private _contract: Contract
  private _contractSigner: Contract
  // Contract of each of the available pools. Are used to get the balance an address owns
  private _poolsContracts: Array<{ pid: number; contract: Contract }>
  private _uniswapRouter02Contract: Contract

  constructor(address: string, provider: Web3Provider, ethPriceUsd?: BigNumber | null) {
    this._userAddress = address
    this._ethPriceUsd = ethPriceUsd?.isGreaterThan(0) ? ethPriceUsd : undefined
    const contractAddress = KBreederContract
    this._readOnlyContract = new Contract(contractAddress, ABI, provider)
    this._signer = provider.getSigner()
    this._contract = new Contract(contractAddress, ABI, this._signer)
    this._contractSigner = this._contract.connect(this._signer)
    this._poolsContracts = stakingPools.map((pool) => {
      const poolABI = pool.lp ? uniswapV2PairABI : tokensABI
      return { pid: pool.PID, contract: new Contract(pool.contract, poolABI, provider) }
    })
    this._uniswapRouter02Contract = new Contract(uniswapRouter02Contract, uniswapRouter02ABI, provider)
  }

  // * Getters----------------------------------------
  public get getData() {
    return {
      _userAddress: this._userAddress,
      _readOnlyContract: this._readOnlyContract,
      _contract: this._contract,
      _contractSigner: this._contractSigner,
    }
  }

  public get ethPriceUsd() {
    return this._ethPriceUsd
  }

  public get userAddress() {
    return this._userAddress
  }

  // * Setters------------------------------------------------------
  public set setNewWallet({ userAddress }: { userAddress: string }) {
    this._userAddress = userAddress
  }

  public set setEthPriceUsd(newEthPriceUsd: BigNumber) {
    if (newEthPriceUsd.isLessThan(0)) return
    this._ethPriceUsd = newEthPriceUsd
  }

  // * LP Pool info
  // Returns the reserves of token0 and token1 used to price trades and distribute liquidity.
  // Also returns the block.timestamp (mod 2**32) of the last block during which an interaction occured for the pair.
  public async getReserves(pid: number): Promise<{ _reserve0: BN; _reserve1: BN; _blockTimestampLast: number } | null> {
    if (stakingPools.find((pool) => pool.PID === pid && !pool.lp)) return null
    const contract = this._poolsContracts.find((pool) => pool.pid === pid)?.contract
    if (!contract) {
      console.error('getReserves() Wrong PID to fetch reserves.')
      return null
    }
    const reserves = await contract.getReserves()
    return reserves
  }

  public async price0CumulativeLast(pid: number): Promise<BN | null> {
    if (stakingPools.find((pool) => pool.PID === pid && !pool.lp)) return null
    const contract = this._poolsContracts.find((pool) => pool.pid === pid)?.contract
    if (!contract) {
      console.error('price0CumulativeLast() Wrong PID to fetch reserves.')
      return null
    }
    const price0 = await contract.price0CumulativeLast()
    return price0
  }

  public async price1CumulativeLast(pid: number): Promise<BN | null> {
    if (stakingPools.find((pool) => pool.PID === pid && !pool.lp)) return null
    const contract = this._poolsContracts.find((pool) => pool.pid === pid)?.contract
    if (!contract) {
      console.error('price0CumulativeLast() Wrong PID to fetch reserves.')
      return null
    }
    const price1 = await contract.price1CumulativeLast()
    return price1
  }

  public async lpPriceUsd(pid: number): Promise<BigNumber | null> {
    if (!this._ethPriceUsd) return null
    const pool = stakingPools.find((pool) => pool.PID === pid)
    if (!pool || !pool.lp) {
      console.error('lpPriceUsd() Wrong PID to fetch price.')
      return null
    }
    const reserves = await this.getReserves(pid)
    if (!reserves || !reserves._reserve0 || !reserves._reserve1) return null
    const { _reserve0, _reserve1 } = reserves
    const token0Price = await this.tokenPriceUsd(pool.lp.token1Pid)
    if (token0Price === null) return null
    const reserve0Normalized = Web3.utils.fromWei(_reserve0.toString(), 'ether')
    const reserve1Normalized = Web3.utils.fromWei(_reserve1.toString(), 'ether')
    const reserve0Value = token0Price.multipliedBy(reserve0Normalized.toString())
    const reserve1Value = this._ethPriceUsd.multipliedBy(reserve1Normalized.toString())
    const lpValue = reserve0Value.plus(reserve1Value)
    const totalSupply = await this.totalSupply(pid)
    if (totalSupply === null) return null
    const lpPrice = lpValue.dividedBy(totalSupply)
    return lpPrice
  }

  public async allLpPricesUsd(): Promise<Array<{ pid: number; price: BigNumber | null }>> {
    const allPrices = await Promise.all(
      stakingPools.map(async (pool) => {
        const price = await this.lpPriceUsd(pool.PID)
        return { pid: pool.PID, price }
      })
    )
    const allPricesNoNull = allPrices.filter((pool) => pool.price !== null)
    return allPricesNoNull
  }

  // * Tokens info
  // custom
  public async tokenPrice(pid: number): Promise<BigNumber | null> {
    // * If it's an LP pair price can't be fetched this way
    if (stakingPools.find((pool) => pool.PID === pid && pool.lp)) return null
    const oneToken = Web3.utils.toWei('1', 'ether')
    const address = stakingPools.find((pool) => pool.PID === pid)?.contract
    if (!address) {
      console.error('tokenPrice() Wrong PID to fetch price.')
      return null
    }
    const price: Array<BN[]> = await this._uniswapRouter02Contract.functions.getAmountsOut(oneToken, [
      address,
      wethContract,
    ])
    const priceString = price[0][1].toString()
    const priceBig = new BigNumber(priceString)
    const oneTokenBig = new BigNumber(oneToken)
    const normalizedPrice = priceBig.dividedBy(oneTokenBig)
    return normalizedPrice
  }

  // custom
  public async tokenPriceUsd(pid: number): Promise<BigNumber | null> {
    // * If ethPriceUsd isn't set the tokenPriceUsd can't be calculated
    if (!this._ethPriceUsd) return null
    // * If it's an LP pair price can't be fetched this way
    if (stakingPools.find((pool) => pool.PID === pid && pool.lp)) return null
    const oneToken = Web3.utils.toWei('1', 'ether')
    const address = stakingPools.find((pool) => pool.PID === pid)?.contract
    if (!address) {
      console.error('tokenPriceUsd() Wrong PID to fetch price.')
      return null
    }
    const price: Array<BN[]> = await this._uniswapRouter02Contract.functions.getAmountsOut(oneToken, [
      address,
      wethContract,
    ])
    const priceString = price[0][1].toString()
    const priceBig = new BigNumber(priceString)
    const oneTokenBig = new BigNumber(oneToken)
    const normalizedPrice = priceBig.dividedBy(oneTokenBig)
    return normalizedPrice.multipliedBy(this._ethPriceUsd)
  }

  // custom
  public async allTokensPrice(): Promise<Array<{ pid: number; price: BigNumber | null }>> {
    const allPrices = await Promise.all(
      stakingPools.map(async (pool) => {
        const price = await this.tokenPrice(pool.PID)
        return { pid: pool.PID, price }
      })
    )
    const allPricesNoNull = allPrices.filter((price) => price.price !== null)
    return allPricesNoNull
  }

  // custom
  public async allTokensPriceUsd(): Promise<Array<{ pid: number; price: BigNumber | null }>> {
    const allPrices = await Promise.all(
      stakingPools.map(async (pool) => {
        const price = await this.tokenPriceUsd(pool.PID)
        return { pid: pool.PID, price }
      })
    )
    const allPricesNoNull = allPrices.filter((price) => price.price !== null)
    return allPricesNoNull
  }

  // * LP and tokens info----------------------------------------------------------------
  // custom
  public async totalStakedOnePool(pid: number): Promise<BigNumber | null> {
    const contract = this._poolsContracts.find((pool) => pool.pid === pid)?.contract
    if (!contract) return null
    const totalStaked: BN = await contract.balanceOf(process.env.REACT_APP_KBREEDER_CONTRACT as string)
    if (!totalStaked) return null
    const totalStakedNormalized = new BigNumber(Web3.utils.fromWei(totalStaked.toString(), 'ether'))
    return !totalStakedNormalized.isFinite() || totalStakedNormalized.isNaN() ? null : totalStakedNormalized
  }

  // custom
  public async totalStaked(): Promise<Array<{ pid: number; totalStaked: BigNumber | null }>> {
    return await Promise.all(
      stakingPools.map(async (pool) => {
        const totalStakedThisPool = await this.totalStakedOnePool(pool.PID)
        if (totalStakedThisPool === null) return { pid: pool.PID, totalStaked: null }
        return { pid: pool.PID, totalStaked: totalStakedThisPool }
      })
    )
  }
  //Call a LP pool details in KumaBreeder
  public async poolInfo(pid: number): Promise<{
    lpToken: string
    allocPoint: BigNumber
    lastRewardBlock: BigNumber
    accSushiPerShare: BigNumber // times 1e12 | Web3.utils.fromWei(accSushiPerShare.toString(), 'micro')
    poolFee: BigNumber
    limitPerWallet: BigNumber
  }> {
    let { lpToken, allocPoint, lastRewardBlock, accSushiPerShare, poolFee, limitPerWallet } =
      await this._readOnlyContract.poolInfo(pid)
    allocPoint = new BigNumber(allocPoint.toString())
    lastRewardBlock = new BigNumber(lastRewardBlock.toString())
    accSushiPerShare = new BigNumber(accSushiPerShare.toString())
    poolFee = new BigNumber(poolFee.toString())
    limitPerWallet = new BigNumber(limitPerWallet.toString())
    return { lpToken, allocPoint, lastRewardBlock, accSushiPerShare, poolFee, limitPerWallet }
  }

  public async allPoolsAllocPoint(): Promise<Array<{ pid: number; allocPoint: BigNumber | null }>> {
    const allAllocPoints = await Promise.all(
      stakingPools.map(async (pool) => {
        const { allocPoint } = await this.poolInfo(pool.PID)
        if (!allocPoint) return { pid: pool.PID, allocPoint: null }
        return { pid: pool.PID, allocPoint }
      })
    )
    return allAllocPoints
  }

  // public async currentNewDkumaPerBlock(pid: number): Promise<BigNumber | null> {
  //   const { allocPoint } = await this.poolInfo(pid)
  //   if (!allocPoint) return null
  //   const sushiReward = SUSHI_PER_BLOCK
  // }

  public async decimals(pid: number): Promise<BigNumber | null> {
    const contract = this._poolsContracts.find((pool) => pool.pid === pid)?.contract
    if (!contract) return null
    const decimals: BN = await contract.decimals()
    return new BigNumber(decimals.toString())
  }

  public async totalSupply(pid: number): Promise<BigNumber | null> {
    const contract = this._poolsContracts.find((pool) => pool.pid === pid)?.contract
    if (!contract) return null
    const supply: BN = await contract.totalSupply()
    return new BigNumber(Web3.utils.fromWei(supply.toString(), 'ether'))
  }

  public async allStakingOptionsPriceUsd(): Promise<Array<{ pid: number; price: BigNumber | null }>> {
    const allLpPricesUsd = await this.allLpPricesUsd()
    const allTokensPricesUsd = await this.allTokensPriceUsd()
    return [...allLpPricesUsd, ...allTokensPricesUsd]
  }

  public getPoolApr(allocPoint: BigNumber, rewardsTokenPrice: BigNumber, poolValue: BigNumber): BigNumber | null {
    const totalRewardPricePerYear = rewardsTokenPrice.times(allocPoint).times(BLOCKS_PER_YEAR)
    const apr = totalRewardPricePerYear.div(poolValue).times(100)
    return apr.isNaN() || !apr.isFinite() ? null : apr
  }

  public getFarmApr(allocPoint: BigNumber, dkumaPriceUsd: BigNumber, poolLiquidityUsd: BigNumber): BigNumber | null {
    if (poolLiquidityUsd.isEqualTo(0)) return null
    const poolWeight = allocPoint.dividedBy(TOTAL_ALLOCATION_POINT)
    const yearlyDkumaRewardAllocation = SUSHI_PER_YEAR.times(poolWeight)
    const apr = yearlyDkumaRewardAllocation.times(dkumaPriceUsd).div(poolLiquidityUsd).times(100)
    return apr.isNaN() || !apr.isFinite() ? null : apr
  }

  public getFarmApy(allocPoint: BigNumber, dkumaPriceUsd: BigNumber, poolLiquidityUsd: BigNumber): BigNumber | null {
    const apr = this.getFarmApr(allocPoint, dkumaPriceUsd, poolLiquidityUsd)
    if (apr == null) return null
    const apy = aprToApy(apr, 48)
    return apy
  }

  public async needsApprove(pid: number, amountToAllow?: string): Promise<boolean | null> {
    const contract = this._poolsContracts.find((contraact) => contraact.pid === pid)?.contract
    if (contract === undefined) return null
    const allowance: BN = await contract.allowance(this._userAddress, KBreederContract)

    if (amountToAllow != null) {
      return new BigNumber(amountToAllow).isGreaterThan(allowance.toString())
    }
    return allowance.isZero()
  }

  public async approve(pid: number): Promise<any> {
    const contract = this._poolsContracts.find((contraact) => contraact.pid === pid)?.contract
    if (contract === undefined) return null
    const writeContract = contract.connect(this._signer)
    const amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    const tx = await writeContract.approve(KBreederContract, amount)
    return tx.wait()
  }

  public async deposit(pid: number, amount: string): Promise<void> {
    const tx = await this._contractSigner.deposit(pid, amount)
    return tx.wait()
  }

  public async withraw(pid: number, amount: string): Promise<void> {
    const tx = await this._contractSigner.withdraw(pid, amount)
    return tx.wait()
  }

  // * User info----------------------------------------------------------------------
  //The amount of Lp tokens in Breeder pool indexes and pending rewards
  public async userInfo(pid: number): Promise<{ amount: BigNumber; rewardDebt: BigNumber }> {
    let { amount, rewardDebt } = await this._readOnlyContract.userInfo(pid, this._userAddress)
    amount = new BigNumber(amount.toString())
    rewardDebt = new BigNumber(rewardDebt.toString())
    return { amount, rewardDebt }
  }

  //The amount of unclaimed rewards of a user of a given pool
  public async pendingSushi(pid: number) {
    const pendingSushi = await this._readOnlyContract.pendingSushi(pid, this._userAddress)
    return new BigNumber(pendingSushi.toString())
  }

  // custom
  public async userTotalYield(): Promise<BigNumber> {
    const allPoolsYield = await Promise.all(
      stakingPools.map(async (pool) => {
        // const { rewardDebt } = await this.userInfo(pool.PID)
        // const rewardString = Web3.utils.fromWei(rewardDebt.toString(), 'ether')
        const pendingRewards = await this.pendingSushi(pool.PID)
        const rewardString = Web3.utils.fromWei(pendingRewards.toFixed(), 'ether')
        return new BigNumber(rewardString)
      })
    )
    return allPoolsYield.reduce((currValue, prevValue) => currValue.plus(prevValue))
  }

  public async userBalance(pid: number): Promise<BigNumber | null> {
    const contract = this._poolsContracts.find((pool) => pool.pid === pid)?.contract
    if (!contract) return null
    const balance = await contract.balanceOf(this._userAddress)
    if (!balance) return null
    const balanceBig = new BigNumber(balance.toString())
    return balanceBig.isNaN() || !balanceBig.isFinite() ? null : balanceBig
  }
}
