import BigNumber from 'bignumber.js'

export const aprToApy = (apr: BigNumber, compounds: number): BigNumber => {
  const apr2 = apr.dividedBy(100)
  const step1 = apr2.dividedBy(compounds)
  console.log('step1', step1.toFixed(3))
  const step2 = step1.plus(1)
  console.log('step2.toFixed(3)', step2.toFixed(3))
  const apy = step2.exponentiatedBy(compounds)
  console.log('apy.toFixed(3)', apy.toFixed(3))
  const finalApy = apy.minus(1).multipliedBy(100)
  // console.log('apr', apr.toFormat())
  // const step1 = apr.dividedBy(compounds)
  // const step2 = step1.plus(1)
  // const step3 = step2.exponentiatedBy(compounds)
  // const apy = step3.minus(1)
  // const numberApr = apr.toNumber()
  // const numberApy = (1 + numberApr / compounds) ** compounds - 1
  // const apy = new BigNumber(numberApy)
  return finalApy
}
