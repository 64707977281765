import './EnforceEthChain.scss'

import React, { FunctionComponent, useRef } from 'react'
import { Warning } from 'src/assets/svg/alerts/Warning'

import { Button } from '../buttons/Button'
import { PromptSwapX } from '../prompts/PromptSwapX'
interface EnforceEthChainProps {
  onClickedSwitchToEthChain: () => void
  onClickDisconnectWallet: () => void
}

export const EnforceEthChain: FunctionComponent<EnforceEthChainProps> = ({
  onClickedSwitchToEthChain,
  onClickDisconnectWallet,
}) => {
  const promptRef = useRef<HTMLInputElement>(null)
  return (
    <div className="enforce_eth_chain__prompt__cover">
      <PromptSwapX
        title="Check your network"
        onClickClose={() => {}}
        refference={promptRef}
        hideCross={true}
        body={
          <>
            <div className="enforce_eth_chain__prompt__body">
              <div className="enforce_eth_chain__prompt__body__info">
                <Warning />
                <p>This website is only available on the Ethereum Blockchain</p>
                {/* <img src={'/img/kuma/rocket.png'} alt={'Kuma rocket'} /> */}
              </div>
              <section className="buttons__container">
                <section className="enforce_eth_chain__prompt__body__button_container switch-network-button">
                  <Button onClick={onClickedSwitchToEthChain} type={'btn__wallet'} size={'btn__large'}>
                    <>
                      <div className="enforce_eth_chain__prompt__body__button_container__provider_container">
                        <p>Switch to ETH Blockchain</p>
                      </div>
                    </>
                  </Button>
                </section>

                <section className="enforce_eth_chain__prompt__body__button_container">
                  <Button onClick={onClickDisconnectWallet} type={'btn__wallet'} size={'btn__large'}>
                    <>
                      <div className="enforce_eth_chain__prompt__body__button_container__provider_container">
                        <p>Disconnect wallet</p>
                      </div>
                    </>
                  </Button>
                </section>
              </section>
            </div>
          </>
        }
      />
    </div>
  )
}
