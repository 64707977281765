import BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'
import { FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { DownArrowImage } from 'src/assets/svg/any/DownArrowImage'
import { KBreederManager } from 'src/classes/KBreederManager'
import { formatAddress } from 'src/common/functions/utils'
import { stakingPools } from 'src/variables/stakingPools'
import useAsyncEffect from 'use-async-effect'
import Web3 from 'web3'

import { useHandleNumericInputs } from '../../hooks/useHandleNumericInput'
import { Loader } from '../loaders/Loader'
import styles from './PoolInfo.module.scss'
const cx = classNames.bind(styles)

interface poolMode {
  mode: { staking?: boolean; unstaking?: boolean }
}

interface PoolInfoProps {
  KBManager?: KBreederManager
  PID: number
  refreshUiInfo: number
  setRefreshUiInfo: (currValue: number) => void
}

export const PoolInfo: FunctionComponent<PoolInfoProps> = ({ KBManager, PID, refreshUiInfo, setRefreshUiInfo }) => {
  // console.log(PID)
  const zeroBig = new BigNumber(0)
  const [mode, setMode] = useState<poolMode['mode']>({ staking: true })
  const stylesStakeButton = cx('stake__unstake__btn', { 'stake__unstake__btn-active': mode.staking })
  const stylesUnstakeButton = cx('stake__unstake__btn', { 'stake__unstake__btn-active': mode.unstaking })

  const [userBalance, setUserBalance] = useState<BigNumber>(zeroBig)
  const [needsApprove, setNeedsApprove] = useState<boolean | null>(null)
  const [userStakedAmount, setUserStakedAmount] = useState<BigNumber>(zeroBig)
  const [userRewards, setUserrewards] = useState<BigNumber>(zeroBig)
  const [totalstaked, setTotalStaked] = useState<BigNumber>(zeroBig)
  const [poolApr, setPoolApr] = useState<BigNumber>(zeroBig)

  const [inputAmountBig, setInputAmountBig] = useState<BigNumber>(zeroBig)
  const inputRef = useRef<HTMLInputElement>(null)
  const [updateCaretPosition, setUpdateCaretPosition] = useState<number>(0)

  const [txOngoing, setTxOngoing] = useState({ stake: false, unstake: false, claim: false, approve: false })

  const thisPool = stakingPools.find((pool) => pool.PID === PID)

  // * Handles the change of value of the input
  // * Updates the string value, the caret position and the BigNumber amount
  const [caretPosition, amountFormatted, handleNewAmountFormattedInput, setAmountFormatted] = useHandleNumericInputs(
    '',
    0,
    {
      changeBigAmount: setInputAmountBig,
      updateCaretPosition: setUpdateCaretPosition,
    }
  )

  const handleSetNeedsApprove = async () => {
    if (!KBManager) return
    const approveIsNeeded = await KBManager.needsApprove(PID, Web3.utils.toWei(inputAmountBig.toFixed()))
    setNeedsApprove(approveIsNeeded)
  }

  // * Updates the caret position when it's told to do so
  useEffect(() => {
    inputRef.current?.setSelectionRange(caretPosition, caretPosition)
  }, [updateCaretPosition])

  useAsyncEffect(async () => {
    handleSetNeedsApprove()
  }, [inputAmountBig])

  useAsyncEffect(async () => {
    if (!KBManager) return
    let doIt = true
    handleSetNeedsApprove()
    // * fetch user staked amount of selected pool
    const { amount } = await KBManager.userInfo(PID)
    const amountNormalized = new BigNumber(Web3.utils.fromWei(amount.toFixed(), 'ether'))
    if (doIt) setUserStakedAmount(amountNormalized)

    // * fetch user dKuma rewards of selected pool
    const pendingSushi = await KBManager.pendingSushi(PID)
    const pendingSushiNormalized = new BigNumber(Web3.utils.fromWei(pendingSushi.toFixed(), 'ether'))
    if (doIt) setUserrewards(pendingSushiNormalized)

    // * fetch user balance of the selected pool in his wallet
    const uBalance = await KBManager.userBalance(PID)
    const uBalanceNormalized = uBalance ? new BigNumber(Web3.utils.fromWei(uBalance?.toFixed(), 'ether')) : zeroBig
    if (doIt) setUserBalance(uBalanceNormalized)

    return () => (doIt = false)
  }, [KBManager, refreshUiInfo])

  useAsyncEffect(async () => {
    if (!KBManager) return
    let doIt = true
    // * Fetch total amount staked of the selected pool
    const allStaked = await KBManager.totalStakedOnePool(PID)
    if (doIt) setTotalStaked(allStaked ?? zeroBig)

    // * Calculates the APR
    const { allocPoint } = await KBManager.poolInfo(PID)
    const dKumaPriceUsd = await KBManager.tokenPriceUsd(0)
    let poolTokenPrice: BigNumber | null = null
    if (thisPool?.lp) {
      poolTokenPrice = await KBManager.lpPriceUsd(PID)
    } else {
      poolTokenPrice = await KBManager.tokenPriceUsd(PID)
    }
    if (allocPoint === null || dKumaPriceUsd === null || poolTokenPrice === null) {
      if (doIt) setPoolApr(zeroBig)
      return
    }
    if (allStaked === null) {
      if (doIt) setPoolApr(zeroBig)
      return
    }
    const poolPrice = allStaked.times(poolTokenPrice)
    const apr = KBManager.getFarmApr(allocPoint, dKumaPriceUsd, poolPrice)
    if (apr === null) {
      if (doIt) setPoolApr(zeroBig)
      return
    }
    if (doIt) setPoolApr(apr)

    return () => (doIt = false)
  }, [KBManager, refreshUiInfo])

  const poolName = thisPool?.name
  const image1 = thisPool?.image[1]
  const image2 = thisPool?.image[2]

  return (
    <div className={cx('staking__box')}>
      <div className={cx('staking__pair__title')}>
        <NavLink className={cx('navLink')} to="/">
          <DownArrowImage style={cx('downArrowImage')} />
        </NavLink>
        <div className={cx('staking__pair__title__imgContainer')}>
          <img src={image1} alt="" />
          {image2 && <img className={cx('eth__image')} src={image2} alt="" />}
        </div>
        <h5>{poolName ?? ''}</h5>
      </div>
      <div className={cx('user__staking__info')}>
        <div>
          <h6>Your stake</h6>
          <p>{userStakedAmount.toFormat(3) ?? '0.00'}</p>
        </div>
        <div>
          <h6>Your rewards</h6>
          <p>{userRewards?.toFormat(3) ?? '0.00'} dKUMA</p>
        </div>
        <div>
          <h6>Pool APR</h6>
          <p>{poolApr.toFormat(1) ?? '0.0'}%</p>
        </div>
      </div>
      <div className={cx('fees_info')}>
        <p>Pool staking fees: 0%</p>
        <p>Pool unstaking fees: 3%</p>
      </div>
      <div className={cx('stake__unstake__btns')}>
        <h6
          className={stylesStakeButton}
          onClick={
            mode.unstaking
              ? () => {
                  setMode({ staking: true })
                  // @ts-ignore
                  setRefreshUiInfo((currValue) => currValue + 1)
                  if (inputAmountBig.isGreaterThan(userBalance)) {
                    setInputAmountBig(userBalance)
                    setAmountFormatted(userBalance.toFormat())
                  }
                }
              : () => {}
          }
        >
          Stake {poolName ?? ''}
        </h6>
        <h6
          className={stylesUnstakeButton}
          onClick={
            mode.staking
              ? () => {
                  setMode({ unstaking: true })
                  // @ts-ignore
                  setRefreshUiInfo((currValue) => currValue + 1)
                  if (inputAmountBig.isGreaterThan(userStakedAmount)) {
                    setInputAmountBig(userStakedAmount)
                    setAmountFormatted(userStakedAmount.toFormat())
                  }
                }
              : () => {}
          }
        >
          UnStake {poolName ?? ''}
        </h6>
      </div>

      <div className={cx('stake__unstake__box')}>
        <h4 className={cx('stake__unstake__box__title')}>
          {mode.staking ? 'Stake' : mode.unstaking ? 'Unstake' : 'Mode Error'} {poolName ?? ''}
        </h4>
        <div className={cx('user__wallet__balance')}>
          <p className={cx('user__wallet')}>{formatAddress(KBManager?.userAddress)}</p>
          <div className={cx('user__balance')}>
            <h6>{mode.staking ? 'Balance: ' : 'Staked: '}</h6>
            <div className={cx('fr')}>
              <h6>{mode.staking ? userBalance.toFormat(3) : userStakedAmount.toFormat(3)}</h6>
              <h6
                className={cx('max__button')}
                onClick={
                  mode.staking
                    ? () => {
                        setAmountFormatted(userBalance.toFormat())
                        setInputAmountBig(userBalance)
                      }
                    : mode.unstaking
                    ? () => {
                        setAmountFormatted(userStakedAmount.toFormat())
                        setInputAmountBig(userStakedAmount)
                      }
                    : () => {}
                }
              >
                Max
              </h6>
            </div>
          </div>
        </div>
        <input
          type="text"
          id="stake-amount-input"
          className={cx('amount__input')}
          placeholder="0.0"
          ref={inputRef}
          value={amountFormatted}
          onBeforeInput={(e) => {
            // @ts-ignore
            if (!parseInt(e.data) && e.data !== '.' && e.data !== ',' && e.data !== '0') e.preventDefault()
          }}
          onChange={(e) => handleNewAmountFormattedInput(e, mode.staking ? userBalance : userStakedAmount)}
          autoComplete="off"
          autoCorrect="off"
        />
        {mode.staking ? (
          needsApprove ? (
            <div
              id="stake-btn"
              className={cx('stake__claim__btn')}
              onClick={
                !KBManager
                  ? () => {}
                  : async () => {
                      setTxOngoing((currValue) => ({ ...currValue, approve: true }))
                      KBManager.approve(PID)
                        .then((approve) => {
                          // @ts-ignore
                          setRefreshUiInfo((currValue) => currValue + 1)
                          setTxOngoing((currValue) => ({ ...currValue, approve: false }))
                        })
                        .catch((error) => {
                          console.error(error)
                          setTxOngoing((currValue) => ({ ...currValue, approve: false }))
                        })
                    }
              }
            >
              <h3>Approve</h3>
              {txOngoing.approve && <Loader size="2.5rem" />}
            </div>
          ) : (
            <div
              id="stake-btn"
              className={cx('stake__claim__btn')}
              onClick={
                !KBManager
                  ? () => {}
                  : async () => {
                      if (inputAmountBig.isGreaterThan(userBalance) || inputAmountBig.isZero()) return
                      setTxOngoing((currValue) => ({ ...currValue, stake: true }))
                      const amount = Web3.utils.toWei(inputAmountBig.toFixed(), 'ether')
                      KBManager.deposit(PID, amount)
                        .then((tx) => {
                          // @ts-ignore
                          setRefreshUiInfo((currValue) => currValue + 1)
                          setAmountFormatted('')
                          setInputAmountBig(zeroBig)
                          setTxOngoing((currValue) => ({ ...currValue, stake: false }))
                        })
                        .catch((error) => {
                          console.error(error)
                          setTxOngoing((currValue) => ({ ...currValue, stake: false }))
                        })
                    }
              }
            >
              <h3>Stake</h3>
              {txOngoing.stake && <Loader size="2.5rem" />}
            </div>
          )
        ) : mode.unstaking ? (
          <div
            id="stake-btn"
            className={cx('stake__claim__btn')}
            onClick={
              !KBManager
                ? () => {}
                : async () => {
                    if (inputAmountBig.isGreaterThan(userStakedAmount) || inputAmountBig.isZero()) return
                    setTxOngoing((currValue) => ({ ...currValue, unstake: true }))
                    const amount = Web3.utils.toWei(inputAmountBig.toFixed(), 'ether')
                    KBManager.withraw(PID, amount)
                      .then((tx) => {
                        // @ts-ignore
                        setRefreshUiInfo((currValue) => currValue + 1)
                        setAmountFormatted('')
                        setInputAmountBig(zeroBig)
                        setTxOngoing((currValue) => ({ ...currValue, unstake: false }))
                      })
                      .catch((error) => {
                        console.error(error)
                        setTxOngoing((currValue) => ({ ...currValue, unstake: false }))
                      })
                  }
            }
          >
            <h3>Unstake</h3>
            {txOngoing.unstake && <Loader size="2.5rem" />}
          </div>
        ) : null}
        <div
          id="claim-btn"
          className={cx('stake__claim__btn')}
          onClick={
            !KBManager
              ? () => {}
              : async () => {
                  if (userRewards.isZero()) return
                  setTxOngoing((currValue) => ({ ...currValue, claim: true }))
                  KBManager.withraw(PID, '0')
                    .then((tx) => {
                      // @ts-ignore
                      setRefreshUiInfo((currValue) => currValue + 1)
                      setTxOngoing((currValue) => ({ ...currValue, claim: false }))
                    })
                    .catch((error) => {
                      console.error(error)
                      setTxOngoing((currValue) => ({ ...currValue, claim: false }))
                    })
                }
          }
        >
          <h3>Claim</h3>
          {txOngoing.claim && <Loader size="2.5rem" />}
        </div>
      </div>
    </div>
  )
}
