import WalletConnectProvider from '@walletconnect/web3-provider'

export const formatAddress = (text?: string): string => {
  if (text) {
    return text.slice(0, 2).concat(text.toUpperCase().slice(2, 6).concat('...').concat(text.toUpperCase().slice(-4)))
  }
  return ''
}

export const getWalletConnectRpcProviders = (): Record<number, string> => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    1: process.env.REACT_APP_ETH_RPC!,
  }
}
export const getWalletConnectProvider = (): WalletConnectProvider => {
  return new WalletConnectProvider({
    rpc: getWalletConnectRpcProviders(),
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    clientMeta: {
      description: 'Cross chain swap over 10 different blockchain',
      url: 'swapx.kuma-inu.com',
      icons: ['https://www.kuma-inu.com/head/main.png'],
      name: 'Kuma SwapX',
    },
  })
}
