import { EthereumProvider } from '@walletconnect/ethereum-provider'

const WALLETCONNECT_PROJECT_ID = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || ''

export const createEthereumProvider = async () => {
  if (WALLETCONNECT_PROJECT_ID === '') {
    throw new Error('WALLETCONNECT_PROJECT_ID is not set')
  }

  const provider = await EthereumProvider.init({
    projectId: WALLETCONNECT_PROJECT_ID,
    chains: [1],
    showQrModal: true,
    metadata: {
      description: 'Stake meme tokens and earn dKUMA rewards',
      url: 'breeder.kumatokens.com',
      icons: ['https://breeder.kumatokens.com/KumaBreeder_Logo.jpg'],
      name: 'Kuma Breeder',
    },
  })

  return provider
}
