import { ApolloClient } from '@apollo/react-hooks'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const apolloClient = new ApolloClient({
  // @ts-ignore
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
  }),
  fetchOptions: {
    mode: 'no-cors',
  },
  // @ts-ignore
  cache: new InMemoryCache(),
})
