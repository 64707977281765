import gql from 'graphql-tag'

// export const ETH_PRICE_QUERY = gql`
//   query bundles {
//     bundles(where: { id: "1" }) {
//       ethPrice
//     }
//   }
// `

export const ETH_PRICE_QUERY = gql`
  query ethPrice {
    bundle(id: "1") {
      ethPrice
    }
  }
`
