import BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'

import styles from './YieldInfo.module.scss'

const cx = classNames.bind(styles)

interface YieldInfoProps {
  value?: BigNumber
}

export const YieldInfo = ({ value }: YieldInfoProps) => {
  const stylesMainContainer = cx('main__container')

  return (
    <div className={stylesMainContainer}>
      <h6>YIELD $dKUMA</h6>
      <h5>{value ? value.toFormat(3) : '0.000'}</h5>
    </div>
  )
}
