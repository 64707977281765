import './PromptSwapX.scss'

import { FunctionComponent, LegacyRef } from 'react'

import { CrossCloseRound } from '../../assets/svg/any/CrossClose'

interface PromptSwapXProps {
  title: string
  titleColor?: string
  warningPrompt?: boolean
  onClickClose: () => void
  body: JSX.Element
  bodyBackground?: string
  width?: string
  refference?: LegacyRef<HTMLDivElement> | undefined
  cover?: boolean
  hideCross?: boolean
}

export const PromptSwapX: FunctionComponent<PromptSwapXProps> = ({
  title,
  titleColor,
  warningPrompt,
  onClickClose,
  body,
  bodyBackground,
  width,
  refference,
  cover,
  hideCross,
}) => {
  return (
    <div
      className={'prompt ' + [titleColor ? `prompt-${titleColor} ` : ''] + [width ? 'prompt-' + width : '']}
      ref={refference}
    >
      {cover && <div className="prompt__cover"></div>}
      <div className={'prompt__head ' + [warningPrompt ? 'prompt__head-warning' : '']}>
        <h6>{title}</h6>
        {hideCross ? (
          ''
        ) : (
          <p className="prompt__close" onClick={onClickClose}>
            <CrossCloseRound size="2rem" />
          </p>
        )}
      </div>
      <div className={'prompt__body ' + [bodyBackground ? 'prompt__body-' + bodyBackground : '']}>{body}</div>
    </div>
  )
}
